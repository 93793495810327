// @flow

import React from 'react'
import type { Node } from 'react'

import { map } from 'ramda'
import { graphql, useStaticQuery } from 'gatsby'

import Layout from '../components/layout'
import { Heading1, BodyText } from '../components/typography'

import { renderPolaroidImage } from '../helpers/contentfulPageImageRenderer'
import type { PolaroidImage as PolaroidImageType } from '../types'

type QueryResponse = {|
  paradisePointPage: {
    images: PolaroidImageType[],
  },
|}

type ParadisePointContentProps = {
  images: PolaroidImageType[],
}

function ParadisePointContent({ images }: ParadisePointContentProps) {
  const polaroidImages = map(renderPolaroidImage, images)
  const renderImages =
    images.length > 0 ? <div id="gallery">{polaroidImages}</div> : null
  return (
    <Layout>
      <div className="bg-purple text-white">
        <div className="pt-12 pb-2 md:pt-16 px-8">
          <div className="w-full md:max-w-md md:mx-auto">
            <div className="mb-6">
              <Heading1 isTitle className="mb-3">
                About Paradise Point
              </Heading1>
              <BodyText className="text-purple-lighter leading-tight">
                Paradise Point is stunning gem on the white sandy beaches of
                Estero Island in Fort Myers Beach.
              </BodyText>
            </div>
            {renderImages}
          </div>
        </div>
      </div>
    </Layout>
  )
}

function ParadisePointPage(): Node {
  const data: QueryResponse = useStaticQuery(graphql`
    query ParadisePointPageQuery {
      paradisePointPage: contentfulPage(slug: { eq: "ParadisePoint" }) {
        images {
          id
          description
          image: fixed(width: 1800, quality: 60) {
            src
          }
        }
      }
    }
  `)

  return <ParadisePointContent images={data.paradisePointPage.images} />
}

export default ParadisePointPage
