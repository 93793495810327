// @flow

import React from 'react'
import type { Element } from 'react'

import { Image, BodyText } from '..'

type PolaroidImageProps = {
  image: {
    src: string,
  },
  id?: string,
  description?: string,
}

export function PolaroidImage({
  id,
  description,
  image,
}: PolaroidImageProps): Element<'div'> {
  return (
    <div
      id={id}
      className="bg-white text-grey-darkest rounded shadow-lg mb-8 md:max-w-md overflow-hidden p-6 md:mx-auto"
    >
      <Image src={image.src} alt="" />
      {description ? (
        <BodyText className="mt-4 text-center">{description}</BodyText>
      ) : null}
    </div>
  )
}
PolaroidImage.defaultProps = {
  id: null,
  description: null,
}

export default PolaroidImage
