// @flow

import React from 'react'
import type { Element } from 'react'

import { PolaroidImage } from '../components/polaroid-image'
import type { PolaroidImage as PolaroidImageType } from '../types/polaroidImage'

export const renderPolaroidImage = ({
  id,
  image,
  description,
}: PolaroidImageType): Element<any> => (
  <PolaroidImage
    key={id}
    id={id}
    description={description}
    image={{ src: image.src, alt: '' }}
  />
)

export default renderPolaroidImage
